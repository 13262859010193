html, body {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #666;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.map-start-icon {*/
/*    background: red;*/
/*    color: white;*/
/*    min-width: 30px;*/
/*    min-height: 30px;*/
/*    border-radius: 15px;*/
/*    text-align: center;*/
/*    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);*/
/*    -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);*/
/*    -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);*/
/*}*/

.map-icon-shadow {
    background-image: url("./assets/images/markers/marker-shadow.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 41px;
    min-width: 25px;
    min-height: 41px;
    text-align: center;
    position: absolute;
    left: 15px;
    top: 5px;
    transform: rotate(30deg);
}

.map-icon-number {
    width: 19px;
    height: 19px;
    background: #f5f4f4;
    margin: 4px auto auto;
    border-radius: 10px;
    font-size: 12px;
    padding-top: 0;
    font-weight: bold;
    color: #666;
}

.map-icon-number-selected {
    width: 19px;
    height: 19px;
    background: #cbcbcb;
    margin: 4px auto auto;
    border-radius: 10px;
    font-size: 12px;
    padding-top: 0;
    font-weight: bold;
    color: black;
}

.map-icon-number-last-click {
    width: 19px;
    height: 19px;
    background: #eee;
    margin: 4px 3px;
    border-radius: 10px;
    font-size: 12px;
    padding-top: 0;
    font-weight: bold;
    color: #888;
}

.map-icon-start {
    background-image: url("assets/images/markers/marker-icon-red-2x.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 41px;
    min-width: 25px;
    min-height: 41px;
    text-align: center;
}

.map-icon-waypoint {
    background-image: url("assets/images/markers/marker-icon-blue-2x.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 41px;
    min-width: 25px;
    min-height: 41px;
    text-align: center;
}

.map-icon-end {
    background-image: url("assets/images/markers/marker-icon-green-2x.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 41px;
    min-width: 25px;
    min-height: 41px;
    text-align: center;
}

.map-icon-current-user {
    background-image: url("assets/images/markers/marker-icon-ball-green.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 20px 20px;
    min-width: 20px;
    min-height: 20px;
}

.map-icon-last-click-location {
    background-image: url("assets/images/markers/marker-icon-yellow-2x.png");
    background-repeat: no-repeat, no-repeat;
    background-size: 25px 41px;
    min-width: 25px;
    min-height: 41px;
    text-align: center;
}

/*.map-icon-last-click-location {*/
/*    background-image: url("assets/images/markers/marker-current-location.png");*/
/*    background-repeat: no-repeat, no-repeat;*/
/*    background-size: 20px 20px;*/
/*    min-width: 20px;*/
/*    min-height: 20px;*/
/*}*/

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}
.table-row-selected {
    background-color: #e3e3e3;
}

.table-row-align-top {
    vertical-align: top;
    text-align: left;
}

tr.drop-over-downward td {
    border-bottom: 2px dashed #1677ff !important;
}

tr.drop-over-upward td {
    border-top: 2px dashed #1677ff !important;
}
